<template>
 <div id="" class="">
  <div class="">
   <PbxDashboard
    :key="pbxElements?.length"
    v-model:selector="periodSelector"
    :dataSeriesCurrentMonth="is_filtered ? filterNoData : noFilteredData"
    @timeSelector="
     is_default ? getDataFromElement() : getDataFromElementTop10()
    "
    @action="is_default ? getDataFromElement() : getDataFromElementTop10()"
    @button-selector="(...args) => getSelectedButton(...args)"
    :extensions="extensions"
    :dataSeriesStacked="dataSeriesStacked"
    :urlType="urlType"
    :dataSeriesConcatenated="
     is_default ? dataSeriesConcatenated : dataSeriesConcatenatedTop
    "
    :periodSelector="this.periodSelector"
    @all-selected="allSelected($event)"
    :buttonSelected="this.buttonSelected"
    :textMonth="this.textMonth"
    :timeSelected="this.timeSelected"
    @setPeriod="(...args) => setPeriod(...args)"
    @is_default="is_default = $event"
    @is_filtered="is_filtered = $event"
    :startDate="startDate"
    :endDate="endDate"
    @setLoading="$emit('setLoading', $event)"
    @concatenate="concatenate = $event" />
   <!-- <div v-show="extensions.length === 0">
    <EmptyStateDashboard />
   </div> -->
  </div>
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage" />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";

const elementTypes = {
 0: "interne",
 1: "externe",
 2: "undefined (2)",
 4: "file",
 5: "voicemail",
 6: "ivr",
 8: "undefined (8)",
 9: "undefined (9)",
 12: "undefined (12)",
 13: "externe nombre",
 14: "call flow",
 999: "not provided",
};

export default {
 props: ["urlType"],
 components: {
  EmptyStateDashboard,
  Loading,
  PbxDashboard,
 },
 data() {
  return {
   concatenate: false,
   is_filtered: true,
   is_default: true,
   elementTypes,
   isLoading: false,
   fullPage: true,
   periodSelector: undefined,
   dataSeriesCurrentMonth: [],
   dataSeriesCurrentMonthTop: [],
   extensions: [],
   dataSeriesStacked: [],
   dataSeriesConcatenated: [],
   dataSeriesStackedTop: [],
   dataSeriesConcatenatedTop: [],
   timeSelected: [],
   isAllSelected: "",
   buttonSelected: this.$t("dashboards.month"),
   textMonth: "",
   data: undefined,
   startDate: null,
   endDate: null,
  };
 },
 methods: {
  setPeriod(...args) {
   this.startDate = args[0];
   this.endDate = args[1];
   this.is_default ? this.getDataFromElement() : this.getDataFromElementTop10();
  },
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  allSelected(val) {
   this.isAllSelected = val;
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async getPbxData() {
   if (this.startDate && this.endDate && this.pbxElements) {
    this.$store.dispatch("pbxElementType", this.urlType);

    let arrayOfElements = [];
    let stringOfElements = null;
    let pbxElements = undefined;
    if (
     !this.pbxElements &&
     this.$route.query.tableElement &&
     this.isQueryParam
    ) {
     this.queryElement = new Array(this.$route.query.tableElement);
     pbxElements = new Array(this.$route.query.tableElement);
     this.$store.dispatch("pbxElements", pbxElements);
    } else {
     pbxElements = this.pbxElements;
    }
    this.pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });
    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);

    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
     this.hostName
    }/origin/${this.urlType}?start_date=${this.startDate}&end_date=${
     this.endDate
    }&start_time=${this.pbxTimeSelected[0]}&end_time=${
     this.pbxTimeSelected[1]
    }&dn=${stringOfElements}`;
    if (!this.is_default) {
     url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
      this.hostName
     }/origin-top/${this.urlType}?start_date=${this.startDate}&end_date=${
      this.endDate
     }&start_time=${this.pbxTimeSelected[0]}&end_time=${
      this.pbxTimeSelected[1]
     }&dn=${stringOfElements}`;
    }
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });

     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async getDataFromElement() {
   this.extensions = [];
   this.dataSeriesCurrentMonth = [];
   this.dataSeriesConcatenated = [];
   const result = await this.getPbxData();
   if (result) {
    this.dataSeriesCurrentMonth = this.formatDataOrigin(result?.data);
    this.dataSeriesConcatenated = this.formatDataOriginConcat(
     result?.consolidated
    );
   }
  },
  async getDataFromElementTop10() {
   this.extensions = [];
   this.dataSeriesCurrentMonthTop = [];
   this.dataSeriesConcatenatedTop = [];
   const result = await this.getPbxData();
   if (result) {
    this.dataSeriesCurrentMonthTop = this.formatDataOriginTop(result?.data);
    this.dataSeriesConcatenatedTop = this.formatDataOriginTopConcat(
     result?.consolidated
    );
   }
  },
  formatDataOrigin(inputData) {
   // Transform the data
   const transformedData = Object.values(inputData).map(item => {
    const dataObj = {};
    let displayName = `${item.dst_dn} - ${
     this.urlType === "queue"
      ? this.pbxMap?.call_queues[item.dst_dn]?.name
      : this.urlType === "extension"
      ? this.pbxMap?.extensions[item.dst_dn]
      : item.dst_dn
    }`;
    this.extensions.push(displayName);

    item.details.forEach(detail => {
     const { origin_dn_type, inbound_calls } = detail;

     dataObj[this.elementTypes[origin_dn_type]] = {
      dataObjSerie: inbound_calls,
      dataObjColor: this.getElementColor(this.elementTypes[origin_dn_type]),
     };
    });

    return {
     name: `${displayName}`,
     data: dataObj,
    };
   });
   return transformedData;
  },
  formatDataOriginConcat(inputData) {
   const aggregatedData = inputData.reduce((acc, item) => {
    const { origin_dn_type, inbound_calls } = item;
    const typeKey =
     this.elementTypes[origin_dn_type] || `type_${origin_dn_type}`;
    const color =
     this.getElementColor(this.elementTypes[origin_dn_type]) || "#000000"; // Default color for undefined types

    this.extensions = this.pbxElements.map(dn => {
     let obj = {};
     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;
     return `${displayName}`;
    });

    // Initialize if not already present
    if (!acc[typeKey]) {
     acc[typeKey] = { dataObjSerie: 0, dataObjColor: color };
    }

    // Sum inbound_calls
    acc[typeKey].dataObjSerie += parseInt(inbound_calls, 10);

    return acc;
   }, {});

   // Transform the aggregated data into the desired format
   const transformedData = [
    {
     name: "Consolidée", // Hardcoded name
     data: aggregatedData, // Directly use the aggregated data
    },
   ];

   return transformedData;
  },
  formatDataOriginTop(inputData) {
   // Transform the data
   const transformedData = Object.values(inputData).map(item => {
    const dataObj = {};
    let displayName = `${item.dst_dn} - ${
     this.urlType === "queue"
      ? this.pbxMap?.call_queues[item.dst_dn]?.name
      : this.urlType === "extension"
      ? this.pbxMap?.extensions[item.dst_dn]
      : item.dst_dn
    }`;
    this.extensions.push(displayName);

    item.details.forEach(detail => {
     const { origin_dn, origin_display_name, origin_dn_type, inbound_calls } =
      detail;

     dataObj[`${origin_dn}-${origin_display_name}`] = {
      dataObjSerie: inbound_calls,
      dataObjColor: this.getElementColor(this.elementTypes[origin_dn_type]),
     };
    });

    return {
     name: `${displayName}`,
     data: dataObj,
    };
   });
   return transformedData;
  },
  formatDataOriginTopConcat(inputData) {
   const aggregatedData = inputData.reduce((acc, item) => {
    const { origin_dn, origin_display_name, origin_dn_type, inbound_calls } =
     item;
    const typeKey =
     this.elementTypes[origin_dn_type] || `type_${origin_dn_type}`;
    const color =
     this.getElementColor(this.elementTypes[origin_dn_type]) || "#000000"; // Default color for undefined types

    this.extensions = this.pbxElements.map(dn => {
     let obj = {};
     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;
     return `${displayName}`;
    });

    // Initialize if not already present
    if (!acc[`${origin_dn}-${origin_display_name}`]) {
     acc[`${origin_dn}-${origin_display_name}`] = {
      dataObjSerie: 0,
      dataObjColor: color,
     };
    }

    // Sum inbound_calls
    acc[`${origin_dn}-${origin_display_name}`].dataObjSerie += parseInt(
     inbound_calls,
     10
    );

    return acc;
   }, {});

   // Transform the aggregated data into the desired format
   const transformedData = [
    {
     name: "Consolidée", // Hardcoded name
     data: aggregatedData, // Directly use the aggregated data
    },
   ];

   return transformedData;
  },
  getElementColor(type) {
   let color = "";
   if (type === "queue") {
    color = "#97BAA6";
   } else if (type === "interne") {
    color = "#14233C";
   } else if (type === "externe") {
    color = "#E34B5F";
   } else if (type === "ivr") {
    color = "#194E63";
   } else if (type === "externe nombre failed") {
    color = "#1E7889";
   } else if (type === "call flow") {
    color = "#D9B3A3";
   } else if (type === "voicemail") {
    color = "#EFB0A1";
   } else {
    color = "#1E7889";
   }
   return color;
  },
 },
 watch: {
  // periodSelector: function (val) {
  //  this.getDataFromElement();
  // },
  is_default: function (val) {
   if (val) {
    this.getDataFromElement();
   } else {
    this.getDataFromElementTop10();
   }
  },
  urlType: function (val) {
   this.$store.dispatch("pbxElements", null);
  },
 },
 mounted() {
  // if (!this.periodSelector && !this.pbxPeriodSelected) {
  //  this.periodSelector = this.currentMonthAndYearForPbx;
  // }
  // if (this.pbxPeriodSelected) this.periodSelector = this.pbxPeriodSelected;

  if (this.pbxElementType !== this.urlType) {
   this.$store.dispatch("pbxElements", null);
  }
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostContext",
   "hostTimeZone",
   "pbxElementType",
   "pbxPeriodSelected",
   "pbxMap",
  ]),
  filterNoData() {
   let data = this.is_default
    ? this.dataSeriesCurrentMonth
    : this.dataSeriesCurrentMonthTop;

   return data.filter(res => {
    return Object.keys(res?.data).indexOf("No_data") < 0;
   });
  },
  noFilteredData() {
   let data = this.is_default
    ? this.dataSeriesCurrentMonth
    : this.dataSeriesCurrentMonthTop;

   return data;
  },
  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options)
    .format(month)
    .slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
